import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #f0f2f5, #ffffff);
  font-family: 'Roboto', sans-serif;
`;

const Box = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
`;

const Message = styled.div`
  font-size: 18px;
  color: #333;
  text-align: left;

  p {
    margin: 0;
    margin-bottom: 10px;
  }

  small {
    font-size: 14px;
    color: #666;
  }
`;

function MessagePage() {
  const location = useLocation();
  const { message } = location.state || { message: 'No message available' };

  return (
    <Container>
      <Box>
        <Message dangerouslySetInnerHTML={{ __html: message }} />
      </Box>
    </Container>
  );
}

export default MessagePage;
