import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #f0f2f5, #ffffff);
  font-family: 'Roboto', sans-serif;
`;

const Box = styled.div`
  background: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
`;

const Title = styled.h1`
  margin-bottom: 24px;
  font-size: 28px;
  color: #333;
  font-weight: 500;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  text-align: left;
`;

const CheckboxLabel = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: 16px;
  text-align: left;
`;

const RadioButtonLabel = styled.label`
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
`;

const RadioButton = styled.input`
  margin-right: 10px;
`;

const Button = styled.button`
  padding: 12px;
  border: none;
  border-radius: 4px;
  background-color: #ffe537;
  color: black;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e8bb27;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

const LoadingImage = styled.img`
  width: 50px;
  height: 50px;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 20px;
  text-align: center;
`;

function LoginForm() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [birthdayOption, setBirthdayOption] = useState('');
  const navigate = useNavigate();

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedOptions([...selectedOptions, value]);
    } else {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    }
  };

  const handleRadioChange = (event) => {
    setBirthdayOption(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedOptions.length === 0 && !birthdayOption) {
      setError('Selecione pelo menos uma lista de alunos.');
      return;
    }
    setLoading(true);
    setError('');
      
    try {
      const response = await axios.post('/api/auth/login', { 
              username: String(username).toLowerCase().trim(), 
              password, 
              selectedOptions, 
              birthdayOption 
            });
                                        
      navigate('/message', { state: { message: response.data.message } });
    } catch (error) {
      console.error('Login failed:', error);
      setError(error.response ? error.response.data.error : 'An error occurred during processing');
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box>
        <Title>CRM Analytics</Title>
        <Form onSubmit={handleSubmit}>
          <Input 
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            placeholder="E-mail Angular (Gerência)" 
            required 
          />
          <Input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="Senha (não será armazenada)" 
            required 
          />

          <Label>Adicionar alunos aniversariantes:</Label>
          <RadioButtonGroup>
            <RadioButtonLabel>
              <RadioButton 
                type="radio" 
                name="birthdayOption" 
                value="aniversariantes" 
                onChange={handleRadioChange}
                checked={birthdayOption === 'aniversariantes'}
              />
              Com visita nos últimos 3 meses
            </RadioButtonLabel>
            <RadioButtonLabel>
              <RadioButton 
                type="radio" 
                name="birthdayOption" 
                value="aniversariantes_completo" 
                onChange={handleRadioChange}
                checked={birthdayOption === 'aniversariantes_completo'}
              />
              Todos os aniversariantes do dia de hoje
            </RadioButtonLabel>
            <RadioButtonLabel>
              <RadioButton 
                type="radio" 
                name="birthdayOption" 
                value="aniversariantes_mes" 
                onChange={handleRadioChange}
                checked={birthdayOption === 'aniversariantes_mes'}
              />
              Todos os aniversariantes do mês
            </RadioButtonLabel>
          </RadioButtonGroup>

          <Label>Adicionar listas de <b>Black Month</b> com alunos que:</Label>
          <CheckboxGroup>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="black_month_velo_test" 
                onChange={handleCheckboxChange} 
              />
              Usaram apenas o Velo Test
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="black_month_um_credito" 
                onChange={handleCheckboxChange} 
              />
              Fizeram apenas 1 aula
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="black_month_zero_aulas" 
                onChange={handleCheckboxChange} 
              />
              Não fizeram aulas ainda
            </CheckboxLabel>
          </CheckboxGroup>
                  
          <Label>Adicionar outras listas de alunos:</Label>
          <CheckboxGroup>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="poucos_creditos" 
                onChange={handleCheckboxChange} 
              />
              Com poucos créditos
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="sem_creditos" 
                onChange={handleCheckboxChange} 
              />
              Sem créditos
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="frequentes_sem_vir" 
                onChange={handleCheckboxChange} 
              />
              Frequentes que estão sem vir
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="primeira_aula_recente" 
                onChange={handleCheckboxChange} 
              />
              Que fizeram a primeira aula recentemente
            </CheckboxLabel>
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="velo_test_expirando" 
                onChange={handleCheckboxChange} 
              />
              Velo Test expirando
            </CheckboxLabel>
                    
            <CheckboxLabel>
              <Checkbox 
                type="checkbox" 
                value="5_promo_expirando" 
                onChange={handleCheckboxChange} 
              />
              Velo 5 Promo expirando
            </CheckboxLabel>
          </CheckboxGroup>

          {!loading && <Button type="submit">Gerar planilha de CRM</Button>}
          {loading && (
            <LoadingContainer>
              <LoadingImage src="/loading-spinner.gif" alt="Loading" />
            </LoadingContainer>
          )}
        </Form>
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Box>
    </Container>
  );
}

export default LoginForm;
